import React from 'react';

import { colors } from '../../styles/variables';

import Navbar from '../../components/organisms/Navbar';
import {
    SectionPopupOrderedItems,
    SectionPopupIconItems,
    SectionPopupBigTiles,
} from '../../components/sections';

import {
    START_ORDERED_ITEMS_CONFIG,
    START_POPUP_ICON_ITEMS_CONFIG,
    START_BIG_TILES_CONFIG,
} from '../../content_config_popup';

import { SPopupContainer } from '../../styles/globalStyles';

const IndexPopup = () => (
    <SPopupContainer>
        <Navbar popupNavbar />

        <SectionPopupOrderedItems
            config={ START_ORDERED_ITEMS_CONFIG }
        />

        <SectionPopupIconItems
            config={ START_POPUP_ICON_ITEMS_CONFIG }
            background={ colors.grayCultured }
        />
        
        <SectionPopupBigTiles
            config={ START_BIG_TILES_CONFIG }
        />
    </SPopupContainer>
);

export default IndexPopup;

import {
    ItemBag,
    ItemBlocks,
    ItemCalendar,
    ItemGift,
} from './assets/images';

import { colors } from './styles/variables';

import {
    howItWorksRef,
    benefitsRef,
    contactRef,
} from './utils/sectionRefs';

export const START_ORDERED_ITEMS_CONFIG = {
    title: "Jak to działa?",
    id: "section-how-it-works",
    forwardedRef: howItWorksRef.forwardedRef,
    navigationRef: howItWorksRef.navigationRef,
    itemList: [
        {
            id: 1,
            title: "Krok 1",
            text: "Robiąc zakupy online, dodajesz produkty do koszyka i wybierasz PayPo jako metodę płatności.",
        },
        {
            id: 2,
            title: "Krok 2",
            text: "Jeśli nie masz jeszcze konta w PayPo, to podajesz swoje dane. Przy kolejnych zakupach potwierdzasz transakcję wyłącznie kodem SMS lub w aplikacji mobilnej",
        },
        {
            id: 3,
            title: "Krok 3",
            text: "PayPo opłaca Twoje zakupy internetowe w sklepie, a Ty otrzymujesz swoje zamówienie.",
        },
        {
            id: 4,
            title: "Krok 4",
            text: "Sprawdzasz zakupy w domu, a na spłatę należności dla PayPo masz aż 30 dni. Pamiętaj, że płacisz tylko za te produkty, które zatrzymasz.",
        }
    ],
    squares: [
        {
            top: "11.5rem",
            left: "-17rem",
            size: "20rem",
            bgColor: colors.paypoGreen500,
            rotate: "45deg",
        }
    ],
};

export const START_POPUP_ICON_ITEMS_CONFIG = {
    title: "Korzyści",
    id: "section-benefits",
    forwardedRef: benefitsRef.forwardedRef,
    navigationRef: benefitsRef.navigationRef,
    content: [
        {
            Icon: ItemCalendar,
            description: "Masz aż 30 dni na zapłatę - <b>bez dodatkowych kosztów</b>",
        },
        {
            Icon: ItemBlocks,
            description: "<b>Robisz pewne zakupy</b> - najpierw odbierasz i sprawdzasz zamówienie, a płacisz dopiero, gdy jesteś pewny zakupu",
        },
        {
            Icon: ItemBag,
            description: "<b>Nie tracisz wyjątkowych okazji</b> - kupujesz, kiedy się najbardziej opłaca, a płacisz wygodnie później",
        },
        {
            Icon: ItemGift,
            description: "<b>Możesz pozwolić sobie na więcej</b> - zrobić prezent sobie lub bliskim albo zaszaleć na wyprzedaży",
        },
    ],
    additionalText: [
        {
            text: 'Usługa jest dostępna dla nowych klientów PayPo. Informacja nie stanowi oferty. Szczegóły w Regulaminie usługi “Zapłać za 30 dni” dostępnym na www.paypo.pl. Klienci PayPo po spłaceniu jednej transakcji w całości, zaczynają korzystać z usług, które umożliwiają spłatę zamówienia w ciągu 30 dni lub w ratach.'
        },
    ],
};

export const START_BIG_TILES_CONFIG = {
    title: "Masz pytanie?",
    id: "section-contact",
    forwardedRef: contactRef.forwardedRef,
    navigationRef: contactRef.navigationRef,
    tileList: [
        {
            id: 1,
            bgColor: colors.mintCream,
            content: {
                title: "bok@paypo.pl",
                textContent: [
                    {
                        id: 1,
                        text: "Na większość pytań odpowiadamy w ciągu 48 godzin",
                    },
                ],
                cta: {
                    type: "button",
                    action: "email",
                    variant: "primary",
                    text: "Napisz do nas"
                }
            },
        },
        {
            id: 2,
            bgColor: colors.palePurple,
            content: {
                title: "+48 22 333 74 60",
                textContent: [
                    {
                        id: 1,
                        text: "Od poniedziałku do piątku od 8:00 do 18:00",
                    },
                    {
                        id: 2,
                        text: "Opłata za połączenie zgodna z taryfą operatora",
                    },
                ],
                cta: {
                    type: "textButton",
                    action: "phoneCall",
                    variant: "textButton",
                    text: "Zadzwoń do nas",
                },
            },
        },
    ],
    squares: [
        {
            top: "-15.2rem",
            left: "-12rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

import styled from 'styled-components';

export const SPopupContainer = styled.main`
    display: flex;
    max-width: 60rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
`;
